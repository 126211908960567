import { ethers } from "ethers";

import EpicNFTs from "./EpicNFTs.json";

export const CONTRACT_ADDRESS = "0x8bf2D43C4C3b06BEc954Ce606E875f5882607A46";
export const connectWallet = async () => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      alert("Get MetaMask!");
      return;
    }
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });
    return accounts[0];
  } catch (error) {
    console.log(error);
  }
};

export const askContractToMintNft = async () => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        EpicNFTs.abi,
        signer
      );

      let nftTxn = await connectedContract.makeAnEpicNFT();
      await nftTxn.wait();
      return `https://rinkeby.etherscan.io/tx/${nftTxn.hash}`;
    } else {
      alert("Get MetaMask!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const setupEventListeners = async ({ connectedContractCallback }) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        EpicNFTs.abi,
        signer
      );

      connectedContract.on("NewEpicNFTMinted", (from, tokenId) => {
        connectedContractCallback(
          `https://testnets.opensea.io/assets/${CONTRACT_ADDRESS}/${tokenId.toNumber()}`
        );
      });
    } else {
      console.log("Get MetaMask!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTotalSupply = async () => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        EpicNFTs.abi,
        signer
      );

      const totalSupply = await connectedContract.getTotalSupply();
      return totalSupply.map((n) => n.toNumber());
    } else {
      console.log("Get MetaMask!");
    }
  } catch (error) {
    console.log(error);
  }
};
