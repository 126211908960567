import React, { useEffect, useState } from "react";

import {
  askContractToMintNft,
  connectWallet,
  getTotalSupply,
  setupEventListeners,
} from "./services/metamask";

import twitterLogo from "./assets/twitter-logo.svg";
import "./styles/App.css";

// Constants
const TWITTER_HANDLE = "_buildspace";
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
const OPENSEA_LINK = "";

const App = () => {
  const [currentAccount, setCurrentAccount] = useState("");
  const [mintedNFTs, setMintedNFTs] = useState([]);
  const [[nextToken, totalSupply], setTotalSupply] = useState([0, 0]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    await setCurrentAccount(connectWallet());
    setTotalSupply(await getTotalSupply());
    await setupEventListeners({
      connectedContractCallback: async (nft) => {
        const newMintedNFTs = [...mintedNFTs, nft];
        setMintedNFTs(newMintedNFTs);
        setTotalSupply(await getTotalSupply());
        setLoading(false);
      },
    });
  }, []);

  return (
    <div className="App">
      <div className="container">
        <div className="header-container">
          <p className="header gradient-text">My NFT Collection</p>
          <p className="sub-text">
            Each unique. Each beautiful. Discover your NFT today.
          </p>
          {currentAccount === "" ? (
            <button
              className="cta-button connect-wallet-button"
              onClick={() => setCurrentAccount(connectWallet())}
            >
              Connect to Wallet
            </button>
          ) : (
            <button
              className="cta-button connect-wallet-button"
              onClick={() => {
                askContractToMintNft();
                setLoading(true);
              }}
              disabled={loading || nextToken === totalSupply}
            >
              {nextToken === totalSupply
                ? "All NFTs collected"
                : loading
                ? "Loading..."
                : `Mint NFT ${nextToken || 1}/${totalSupply}`}
            </button>
          )}
        </div>
        <div className="nfts-container">
          {mintedNFTs.map((nft) => (
            <a href={nft} key={nft}>
              {nft}
            </a>
          ))}
        </div>
        <div className="footer-container">
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`built on @${TWITTER_HANDLE}`}</a>
        </div>
      </div>
    </div>
  );
};

export default App;
